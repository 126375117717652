import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserRolesConstants } from '@constants/user-roles.constants';
import { BEAuthService } from '@services/backend-services/auth/be-auth.service';
import { downloadEncryptedReference, generatePassword, initializeClass } from '@utils/utils';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { IRegisterContext } from '@interfaces/register-context.interface';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { ConstantEntity } from '@constants/base.constants';
import { CompanyRegisterContext } from '@interfaces/company-register-context.interface';
import { ProfileEntity } from '@entities/profile.entity';
import { CompanyEntity } from '@entities/company.entity';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { DataProvidersConstants } from '@constants/data-providers.constants';
import { ClaimInstallationEntity } from '@entities/claim-installation.entity';
import { CountryConstantEntity } from '@constants/country-constant.entity';
import { BaseComponent } from '@shared/components/base.component';
import {
  RegisterLiteActionTokenDataEntity,
  RegisterPlusActionTokenDataEntity,
} from '@entities/action-token-data.entity';
import { BEDesktopService } from '@services/backend-services/desktop/be-desktop.service';
import { serverError } from '@utils/errors';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent extends BaseComponent implements OnChanges {
  public newUsersRole: ConstantEntity = UserRolesConstants.COMPANY_ADMIN;

  public hidePassword = true;
  public waiting = false;

  public countryFormControl: FormControl = new FormControl('', []);
  public userPhonePrefixFormControl: FormControl = new FormControl('', []);
  public companyPhonePrefixFormControl: FormControl = new FormControl(undefined, [
    Validators.required,
  ]);
  public regForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    password: new FormControl('', [
      Validators.required,
      //  Upper and lower case letters, minimum 8 chars
      // Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,}$/),
      //  Minimum 8 chars
      Validators.pattern(/^.{8,}$/),
    ]),
    domain: new FormControl(''),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    position: new FormControl('', []),
    userPhone: new FormControl('', []),
    companyName: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    companyTownOrCity: new FormControl('', []),
    companyStateOrRegion: new FormControl('', []),
    companyCountry: this.countryFormControl,
    companyZipCode: new FormControl('', []),
    companyPhone: new FormControl('', [Validators.required]),
    userPhonePrefix: this.userPhonePrefixFormControl,
    companyPhonePrefix: this.companyPhonePrefixFormControl,
    webSite: new FormControl('', []),
    agreeToPrivacyPolicy: new FormControl(false, [Validators.requiredTrue]),
  });

  public loading = false;
  public progressValue = 6;

  @Input() dataProvider: number;
  @Input() knownCompanyName: string;
  @Input() registerLiteData: RegisterLiteActionTokenDataEntity;
  @Input() registerPlusData: RegisterPlusActionTokenDataEntity;

  constructor(
    @Inject(Window) private window: Window,
    public localStorageService: LocalStorageService,
    private router: Router,
    private toastr: ToastrService,
    private translationMWService: TranslationMWService,
    private beAuthService: BEAuthService,
    private beDesktopService: BEDesktopService
  ) {
    super(localStorageService);

    const currentDomain =
      this.window.location.hostname +
      (this.window.location.port.length ? ':'.concat(this.window.location.port) : '');
    this.regForm.controls.domain.setValue(currentDomain);

    this.regForm.get('userPhone').valueChanges.subscribe(() => {
      if (this.regForm.controls.userPhone.value) {
        this.regForm.controls.userPhonePrefix.setValidators([Validators.required]);
      } else {
        this.regForm.controls.userPhonePrefix.clearValidators();
      }
      this.regForm.controls.userPhonePrefix.updateValueAndValidity();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.registerLiteData && changes.registerLiteData.currentValue) {
      this.regForm.controls.email.setValue(this.registerLiteData.email);
    }
    if (changes.knownCompanyName && changes.knownCompanyName.currentValue) {
      this.regForm.controls.companyName.setValue(this.knownCompanyName);
    }
  }

  public renewPassword() {
    this.regForm.controls.password.setValue(generatePassword());
  }

  public clearPassword() {
    this.regForm.controls.password.setValue('');
  }

  public registerCompany() {
    if (this.regForm.valid) {
      //  Show the loading bar
      this.loading = true;
      this.progressValue = 45;

      const user: IRegisterContext = {
        email: this.regForm.value.email,
        password: this.regForm.value.password,
        domain: this.regForm.value.domain,
      };
      const profile: ProfileEntity = initializeClass(ProfileEntity, this.regForm.value);
      if (this.regForm.value.userPhone) {
        profile.phone =
          '+' + this.regForm.value.userPhonePrefix.phonePrefix + ' ' + this.regForm.value.userPhone;
        profile.phone.replace(/-/g, '');
      } else {
        profile.phone = this.regForm.value.userPhone;
      }
      profile.language = this.currentLanguage;
      const company: CompanyEntity = initializeClass(CompanyEntity, this.regForm.value);
      if (this.regForm.value.companyPhone) {
        company.phone =
          '+' +
          this.regForm.value.companyPhonePrefix.phonePrefix.replace(/-+/g, '') +
          ' ' +
          this.regForm.value.companyPhone;
      } else {
        company.phone = this.regForm.value.companyPhone;
      }
      company.name = this.regForm.value.companyName;
      company.country =
        this.regForm.value.companyCountry instanceof ConstantEntity
          ? this.regForm.value.companyCountry.name.toLowerCase()
          : this.regForm.value.companyCountry;
      const companyRegContext: CompanyRegisterContext = {
        user,
        profile,
        company,
        installation:
          this.dataProvider === DataProvidersConstants.CTCLITE.numberValue
            ? initializeClass(ClaimInstallationEntity, {
                registrationCode: this.registerLiteData.registrationCode,
              })
            : undefined,
        offlineDesktopLink:
          this.dataProvider === DataProvidersConstants.CTCPLUS.numberValue &&
          this.registerPlusData &&
          this.registerPlusData.valid
            ? this.registerPlusData
            : undefined,
      };
      this.beAuthService
        .registerCompany(companyRegContext, this.dataProvider)
        .pipe(first())
        .subscribe(
          (response) => {
            this.progressValue = 75;
            this.loading = false;
            this.toastr.success(
              this.translationMWService.instant(
                `register.${this.newUsersRole.lowerCaseName}.toastr.success.message`
              ),
              this.translationMWService.instant('register.toastr.success.title')
            );
            // show account's email confirmation instructions
            if (response.emailResult) {
              this.toastr.info(
                this.translationMWService.instant(
                  'register.toastr.activate-account-instructions.message',
                  {
                    email: response.user.email,
                  }
                ),
                this.translationMWService.instant(
                  'register.toastr.activate-account-instructions.title'
                ),
                {
                  disableTimeOut: true,
                  closeButton: true,
                }
              );
            } else {
              this.toastr.error(
                this.translationMWService.instant(
                  'register.toastr.activation-email.error.message',
                  {
                    email: response.user.email,
                  }
                ),
                this.translationMWService.instant('register.toastr.activation-email.error.title'),
                {
                  disableTimeOut: true,
                  closeButton: true,
                }
              );
              // TODO: redirect to an activation e-mail request page
            }
            if (this.registerPlusData && this.registerPlusData.validQR) {
              downloadEncryptedReference(this.beDesktopService, this.registerPlusData.reference);
            }
            this.progressValue = 100;
            this.loading = false;
            this.beAuthService.lastRegisteredNext(response.user);
            // navigate to login page
            void this.router.navigate(['/auth/login']);
          },
          (error) => {
            this.loading = false;
            if (error.status === HttpStatus.StatusCodes.CONFLICT) {
              serverError(error, this.toastr, this.translationMWService);
            }
            console.log(this.regForm.value);
            // this.clearPassword();
          }
        );
    }
  }

  onCancelClicked() {
    // navigate to login page
    void this.router.navigate(['/auth/login']);
  }
}
